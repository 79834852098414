import React from 'react';
import './Navbar.css';
import logo from '../../../src/logo.svg';

const Navbar = () => {

    return (
        <nav className="navbar">
          <img src={logo} alt="Logo" className="navbar-logo" />
          <ul className="navbar-links">
            <li><a href="#home">Home</a></li>
            <li><a href="#view-map">JMap</a></li>
            <li><a href="#dashboard">Data insights</a></li>
          </ul>
        </nav>
      );
};

export default Navbar;